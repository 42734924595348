<template>
  <div class="tw-mt-28">
    <v-app-bar bottom fixed elevation="2" color="white" class="tw-w-full" height="76">
      <div class="tw-flex tw-w-full tw-my-3 tw-justify-between ">
        <div>
          <base-button class="tw-flex tw-mr-3" :button-text="skip" outlined v-if="skip"
                       width="auto" @click="$emit('skip')">
          </base-button>
        </div>

        <base-button :width="skip?'70%':'100%'" :button-text="submit_name" @click="$emit('submit')"/>
      </div>
    </v-app-bar>
  </div>

</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "MobileButton",
  components: {BaseButton},
  props: {
    skip: String,
    submit_name: String
  }
}
</script>

<style scoped>

</style>