<template>
  <div class="wrapper ">
    <OnboardingHeader/>
    <div class="lg:tw-pl-11 ">
      <keep-alive>
        <component v-bind:is="displayCreateProfileComponent()"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import StepCount from "@/components/createProfile/reuseables/StepCount";
import StepOne from "@/components/createProfile/components/StepOne";
import StepTwo from "@/components/createProfile/components/StepTwo";
import StepThree from "@/components/createProfile/components/StepThree";
import StepFive from "@/components/createProfile/components/StepFive";
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
import StepEight from "@/components/createProfile/components/StepEight";
import SpecializedPage from "@/components/createProfile/components/SpecializedPage";

export default {
  name: "CreateProfile",
  components: {OnboardingHeader, StepCount},
  data() {
    return {
      currentStep: 3
    }
  },
  methods: {
    handlePrevStep() {
      if (this.currentStep > 1)
        return this.currentStep -= 1
    },
    handleNextStep() {
      if (this.currentStep < 9)
        return this.currentStep += 1
    },
    displayCreateProfileComponent() {
      let param = this.$route.params.step
      if (param === '1') {
        return StepOne
      } else if (param === '2') {
        return StepThree
      } else if (param === '3') {
        return StepTwo
      } else if (param === '4') {
        return StepFive
      } else if (param === '5') {
        return StepEight
      } else if (param === '6') {
        return SpecializedPage
      } else if (param === '7') {
        return
      } else if (param === '8') {
        return
      } else {
        return StepOne
      }
    }
  }
}
</script>

<style scoped lang="scss">

.select-input {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans;
  display: flex;
  align-items: center;
  color: #001343;
  cursor: pointer;
}

.label {
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}
.stepFont{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Enum-Darkblue/2 */

  color: #142E70;
}

.select {
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.select:hover {
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;
}

.wrapper {
  overflow: hidden !important;
}


.select-web {
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.select-web:hover {
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}

.selected {
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;
}

.skills {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: #1E323F;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
</style>
