<template>
  <resume-layout>
    <template v-slot:main-content>
      <div class="stepContent">
<!--        <step-count class="tw-pt-4" :count="+$route.params.step"/>-->
<!--        <step-title class="tw-pt-2" id="portfolio&SocialLinks" title="Portfolio & Social Links"/>-->
        <div class="tw-mb-10">
          <h3 class="step-title tw-mt-4 tw-mb-2">Portfolio & Social Links</h3>
          <span class="profile tw-pl-1 ">We will use this data to personalize your experience,</span>
        </div>
        <div class="lg:tw-mt-8 ">
          <PortfolioLinks/>
        </div>

      </div>
    </template>
  </resume-layout>
</template>

<script>
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import PortfolioLinks from "@/components/createProfile/components/PortfolioLinks";
export default {
  name: "StepEight",
  components: {PortfolioLinks, ResumeLayout}
}
</script>

<style scoped>
.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>