<template>
  <resume-layout>
    <template v-slot:main-content>

      <div class="stepContent">
        <v-form id="createProfile" style="max-width: 500px" lazy-validation @submit.prevent="continueResume">

          <!--        <step-count class="tw-pt-4" :count="+$route.params.step"/>-->
          <!--        <StepTitle class="tw-pt-2" title="Create Resume" id="stepTitle"/>-->
          <!--        <StepDescription description="Please upload or fill your resume to enable us complete your profile"/>-->
          <!--        <div class="lg:tw-mt-12 ">-->
          <!--          <item-card :title="offer" v-for="offer in resumeUploadType" :id="offer"-->
          <!--                     :key="offer" @selectedRemoteType="handleSelectedType(offer)" :selected="selectedType"/>-->
          <!--        </div>-->
          <div class="tw-mb-16">
            <h3 class="step-title tw-mt-4 tw-mb-2">Profile Picture</h3>
            <span class="profile tw-pl-1 ">Drag and drop or upload from device <br/>
<!--              <span style="color: #FF2E2E">(Please note here that it only accepts JPEG and not PNG)</span>-->
            </span>
          </div>
          <div>
            <DragAndDrop @sendResumeCv="getUrl"/>

          </div>
          <div class="tw-flex tw-justify-end tw-pt-10 tw-pb-5">
            <!--          <base-button v-show="selectedType !== 'Upload Resume'" id="skip" outlined class="tw-pr-6" @click="skip"-->
            <!--                       button-text="Skip"/>-->
            <base-button :disabled="isUpload && selectedType === 'Upload Resume'" id="continue" type="submit"
                         :loading="loading"
                         button-text="Continue" width="128">
              <template #right-icon>
                <arrow-right-icon class=" tw-ml-2 custom-class"/>
              </template>
            </base-button>
          </div>
        </v-form>

      </div>
    </template>
  </resume-layout>
</template>

<script>
import {ArrowRightIcon} from 'vue-feather-icons'
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import BaseButton from "@/components/reusables/BaseButton";
import {mapState} from "vuex";
import DragAndDrop from "@/components/createProfile/reuseables/DragAndDrop";
import {uploadMediaFile} from "@/services/mediaFileUploader";
import {updateUserAboutMe} from "@/services/api/APIService";

export default {
  name: "StepThree",
  components: {DragAndDrop, ArrowRightIcon, BaseButton, ResumeLayout},
  data() {
    return {
      loading: false,
      isUpload: false,
      file: null,
      image_url: '',
      resumeUploadType: ["Upload Resume", "I want to fill my resume"],
      selectedType: "",
      image:''
    }
  },
  watch: {
    // '$route': {
    //   handler: function (route) {
    //     if (route.params.step === "3") {
    //     }
    //   }
    // }
  },
  mounted() {
    this.$root.$on('sendResumeCv', (getResumeUrl) => {
      this.resume_url = getResumeUrl
    })
  },
  computed: {
    ...mapState('createProfile', ['applicantCV'])
  },
  methods: {
    async handleImage(file) {
      this.imageUploading = true

      const { url, key, name, size } = await uploadMediaFile(file, 'users_profile')
      this.image = JSON.stringify({url,key})
      this.imageInfo = {url: url, name: name, size: size};

      this.imageUploading = false
    },
    async upload(file) {
      this.loading = true
      this.file = file
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension)) {
        this.file = "";
        return
      }
    },
    // handleSelectedType(selectedOffer) {
    //   this.selectedType = selectedOffer
    // },
    // async loadData(){
    //   let res = await this.$store.dispatch('createProfile/getApplicantCV')
    //   if (res) {
    //     this.file = this.applicantCV[0]
    //     this.isUpload = true
    //     console.log(this.applicantCV)
    //     this.selectedType = "Upload Resume"
    //   }
    // },
    getUrl(data,image) {
      console.log(data)
      this.image_url = data
          this.image = image
    },
    async continueCreateProfile() {
      let user = JSON.parse(sessionStorage.getItem('userInfo'))

      this.loading = true

      await updateUserAboutMe({
        image: this.image,
        about_me:user.about_me,
        user_type: 'talent'
      }).then(async res => {
        console.log(res.data)
        this.loading = false

        sessionStorage.setItem('userImage', JSON.stringify(this.image_url))
        await this.$router.push({
          name: "CreateProfile",
          params: {step: "3"},
        })
      }).catch(err=>{
        this.loading = false

        this.$displaySnackbar({
          message: err.response.data,
          success: false
        })
      })

    },
    async continueResume() {
     await  this.continueCreateProfile();


    }
    // if (this.selectedType === 'I want to fill my resume') {
    //   const data = {}
    //   data.fill_my_resume = true;
    //   try {
    //     await this.$store.dispatch('createProfile/createResume', data)
    //     // this.$displaySnackbar({
    //     //   message: 'Question Saved',
    //     //   success: true
    //     // })
    //     await this.$router.push({
    //       name: "CreateProfile",
    //       params: {step: "4"},
    //     })
    //   } catch (e) {
    //     this.$displaySnackbar({
    //       message: e.response.data.detail,
    //       success: false
    //     })
    //   } finally {
    //     this.loading = false
    //   }
    // } else {
    //   const data = {}
    //   data.name = this.resume_url.resume_name
    //   data.cv_url = this.resume_url.resume_url;
    //   try {
    //     console.log(data)
    //     await this.$store.dispatch('createProfile/createResume', data)
    //     this.$displaySnackbar({
    //       message: 'Resume Uploaded Successfully',
    //       success: true
    //     })
    //     await this.$router.push({
    //       name: "ApplicantDashboard",
    //     })
    //   } catch (e) {
    //     this.$displaySnackbar({
    //       message: e.response.data.detail,
    //       success: false
    //     })
    //   } finally {
    //     this.loading = false
    //   }
    // }
  },
  skip() {
    return this.$router.push({
      name: 'ApplicantDashboard'
    })
  }
}
</script>

<style scoped>
.custom-class {
  color: white;
}

.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

</style>

