<template>
  <div>
    <v-card :loading="loading" :class="{'styled-border': !file}" class="dragCard tw-w-auto" flat v-model="showDragDiv">
      <div
          v-show="file === ''"
          :style="{ 'background-color': color }"
          class="drag-div tw-p-5 md:tw-p-0 tw-bg-enumBlue5"
          @dragleave="dragleave"
          @dragover="dragover"
          @drop="drop"
          id="drop"
      >
        <input
            id="assetsFieldHandle"
            ref="file"
            accept="image/png, image/gif, image/jpeg,image/jpg"
            class="w-px h-px opacity-0 overflow-hidden absolute"
            name="fields[assetsFieldHandle][]"
            style="display: none"
            type="file"
            @change="uploadToCloudinary()"

        />
        <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer"
               for="assetsFieldHandle">
          <UploadIcon class="tw-text-enumGrey2"/>
          <span class="drag-drop mx-auto">
                    <p class=" tw-text-enumGrey2 my-2">Drag and drop or <span
                        class="choose__file">choose Image</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">image, max size 1MB  </p>
                  </span>
        </label>
      </div>
    </v-card>
    <div v-if="file && loading" class="file" style="cursor: pointer">
      <div class="d-flex align-center justify-center pt-3 px-3">
        <span>Loading...</span>
      </div>
    </div>
    <div v-if="file && !loading" class="file" style="cursor: pointer">
      <div class="d-flex align-center pt-3">
        <div class="tw-flex align-center">
          <v-img
              v-if="resume_url"
              :src="resume_url"
              class="round-image pr-2"
              transition="fade"
              height="158"
              width="200"
              contain

          />
          <div class="tw-flex tw-flex-col">
            <span class="tw-pl-3">{{ shortenedTitle(file.name) }}</span>
            <p class="tw-pl-3 fontSize">Size: {{ getSize(file.size) }}</p>
            <span class="image__actions d-flex align-end " :class="{ 'bg-transparent': resume_url }">
            <span class="tw-flex lg:tw-flex-row tw-flex-col  pl-3" v-if="resume_url">
              <v-btn
                  class="mr-3 tw-mb-5 lg:tw-mb-0"
                  depressed
                  @click.prevent="openExplorer"
              >
                Replace
              </v-btn>
              <v-btn class="" depressed @click.prevent="remove">Delete</v-btn>
            </span>
          </span>

          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {UploadIcon} from "vue-feather-icons"
import {mapState} from "vuex";
import {uploadMediaFile} from "@/services/mediaFileUploader";
import {viewFile} from "@/services/utils/aws";

export default {
  name: "DragAndDrop",
  components: {UploadIcon},
  data() {
    return {
      showDragDiv: true,
      website: "",
      logo: "",
      file: "",
      color: "",
      loading: false,
      resume_url: '',
    }
  },
  async created() {
    if (this.applicantCV.length) {
      this.file = this.applicantCV[0]
    }
  },
  computed: {
    ...mapState('createProfile', ['applicantCV'])
  },
  methods: {
    async handleImage(file) {
      const { key, name: resume, size: resume_file_size, url} = await uploadMediaFile(file, 'resume')
      let resumeInfo = {resume_url: key, resume_name: resume, resume_size: resume_file_size};
      let image= JSON.stringify({url,key})

          viewFile({ url: key, context: this, property: 'resume_url'})
      this.$emit('sendResumeCv', resumeInfo,image);
    },

    async uploadToCloudinary() {
      this.loading = true
      this.file = this.$refs.file.files[0];
      const exts = ['jpeg', 'png', 'gif', 'jpg']
      let splitFile = this.file.name.split(".")
      console.log(this.file)
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        console.log('d')
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.file = "";
        this.loading = false
        return
      }
      let size = this.getSize(this.file.size)
      if (size.split(' ')[1] === 'MB' ){
        this.$displaySnackbar({message: 'Maximum Image size is 1MB', success: false})
        this.file = "";
        this.loading = false
        this.showDragDiv = false;
        return
      }
      try {

        await this.handleImage(this.file);
        this.loading = false
      } catch (e) {
        this.loading = false;
        return;
      }
    },
    openExplorer() {
      this.$refs.file.click();
    },
    extractTextFromPDf(file) {
      console.log(file)
    },
    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    getSize(size) {
      if (!size) return "";
      const i = Math.floor(Math.log(size) / Math.log(1024));
      return (
          (Math.ceil(size / Math.pow(1024, i)).toFixed(2) * 1) +
          " " +
          ["B", "kB", "MB", "GB", "TB"][i]
      );
    },
    async onChange() {
      await this.uploadToCloudinary()
      this.showDragDiv = false;
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      // Clean up
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.resume_url = '',
          this.showDragDiv = false;

    }
  }
}
</script>
<style scoped>
p {
  font-family: "DM Sans", serif;
}

.dragCard {
  background: #F7FCFF !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.styled-border {
  /*border: 1px dashed #008EEF;*/
}

.drag-div {
  /*background: #FBFDFE;*/
  border: 0.5px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
  /*width: 420px;*/
  height: 118px;
  /*margin-top: 24px;*/
}

.round-image {
  border-radius: 5px !important;
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.fontSize {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  letter-spacing: 0.01em;

  /* Enum-Grey/3 */

  color: #9CABB5;

}

.file {
  margin-bottom: 10px;
  margin-top: 10px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans", serif;
}

.size__instruction {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #9CABB5;
}
</style>
