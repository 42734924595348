export const removeUnwantedProperties = (model, properties) => {
    let objectKeys = Object.keys(model)
    properties.map(property => {
        objectKeys = objectKeys.filter(key => key !== property)
    })
    let newModel = {}
    objectKeys.map(key => {
        newModel[key] = model[key]
    })
    return newModel
}