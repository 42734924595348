<template>
  <div class="lg:tw-w-2/3 assigned">
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form id="createProfile" lazy-validation @submit.prevent="handleSubmit(moveToStepFive)">
        <div v-for="(info, index) in educations"
             :key="index"
        >
          <div class="lg:tw-flex tw-pb-5">
            <div>
              <ValidationProvider
                  name="School/Training"
                  rules="required"
                  v-slot="{ errors }"
              >
                <p>School/Training<span style="color: red">*</span></p>
                <v-combobox
                    style="min-width: 293px"
                    id="school"
                    :append-icon="info.school_or_training ? 'mdi-chevron-up': 'mdi-chevron-down'"
                    v-model="info.school_or_training" :items="schools" placeholder="University of Lagos" class="label"
                    solo hide-details/>

                <span v-show="errors" class="err">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="lg:tw-ml-5">
              <ValidationProvider
                  name="Degree/Certificate"
                  rules="required"
                  v-slot="{ errors }"
              >
                <p :style="{
                    paddingTop: $vuetify.breakpoint.smAndDown  ? '16px' : '',
                  }">Degree/Certificate<span style="color: red">*</span></p>
                <v-combobox
                    style="min-width: 293px"
                    id="degree"
                    :append-icon="info.degree_or_certificate ? 'mdi-chevron-up': 'mdi-chevron-down'"
                    v-model="info.degree_or_certificate" :items="certifications" placeholder="BSC" class="label" solo
                    hide-details/>
                <span v-show="errors" class="err">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

          </div>
          <div>
            <ValidationProvider
                name="Program/Course"
                rules="required"
                v-slot="{ errors }"
            >
              <p>Program/Course<span style="color: red">*</span></p>
              <v-combobox
                  :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                  }"
                  full-width
                  :append-icon="info.program_or_course ? 'mdi-chevron-up': 'mdi-chevron-down'"
                  id="course"
                  v-model="info.program_or_course" :items="courses" placeholder="Computer Science" class="label" solo
                  hide-details/>

              <span v-show="errors" class="err">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="lg:tw-flex tw-pb-5 tw-pt-5">
            <div>
              <p>Start Date</p>
              <v-menu
                  ref="menu"
                  v-model="info.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      style="min-width: 293px"
                      solo
                      hide-details
                      readonly
                      :append-icon="info.menu ? 'mdi-chevron-up': 'mdi-chevron-down'"
                      placeholder="MM/YY"
                      full-width
                      v-model="info.start_date"
                      v-bind="attrs"
                      v-on="on"
                      id="startDate"
                  >
                    <template #append>
                      <vue-feather type="calendar" @click="info.menu = !info.menu" style="color: #475661; cursor: pointer"/>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="info.start_date"
                    min="1980"
                    scrollable
                    no-title
                    width="300"
                    :max="educations[index].end_date? educations[index].end_date :new Date().toISOString().substr(0, 10)"
                    @input="info.menu = false"
                    id="startDate"
                ></v-date-picker>
              </v-menu>
            </div>
            <div v-if="!info.still_enrolled"
                 class="lg:tw-ml-5">
              <p :style="{
                    paddingTop: $vuetify.breakpoint.smAndDown  ? '16px' : '',
                  }">End Date</p>
              <v-menu
                  ref="menu2"
                  v-model="info.menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      style="min-width: 293px"
                      solo
                      hide-details
                      readonly
                      placeholder="MM/YY"
                      full-width
                      :append-icon="info.menu2 ? 'mdi-chevron-up': 'mdi-chevron-down'"
                      v-model="info.end_date"
                      v-bind="attrs"
                      v-on="on"
                      id="endDate"
                  >
                    <template #append>
                      <vue-feather type="calendar" @click="info.menu2 = !info.menu2" style="color: #475661; cursor: pointer"/>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="info.end_date"
                    scrollable
                    no-title
                    width="300"
                    :min="educations[index].start_date"
                    :max="new Date().toISOString().substr(0, 10)"
                    @input="info.menu2 = false"
                    id="endDate"
                ></v-date-picker>
              </v-menu>

            </div>

          </div>

          <div class="d-flex  align-center tw-justify-between" :style="{ minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
}">
            <v-checkbox
                v-model="info.still_enrolled"
                @change="[toggleAm(index)]"
                label="I’m still enrolled in this program"
                color="purple"
            />

            <div v-if="education_history.length >1" id="deleteExperience" class="delete"
                 @click="[deleteExperience(index)]">
              <vue-feather size="17" type="trash-2"/>
            </div>
          </div>

          <v-divider class="tw-pb-6" :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                  }"/>

        </div>

        <div :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                    paddingLeft: $vuetify.breakpoint.smAndDown  ? '2rem' : '',
                  }"
             class="tw-flex  lg:tw-pt-5 tw-pb-5 tw-justify-between">
          <base-button color="" id="addExperience" button-text="Add School" text @click="addExperience">
            <template #left-icon>
              <PlusIcon/>
            </template>
          </base-button>

          <base-button
              v-if="!$vuetify.breakpoint.smAndDown"
              id="moveToStepFour" :button-text="$route.query.edit === 'edit' ? 'Save':'Continue'" width="128"
              type="submit">
            <template #right-icon>
              <arrow-right-icon class=" tw-ml-2 custom-class"/>
            </template>
          </base-button>
          <div v-else>
            <MobileButton type="submit" submit_name="Continue"/>
          </div>
        </div>

      </v-form>
    </ValidationObserver>
  </div>
</template>
<script>
import BaseButton from "@/components/reusables/BaseButton";
import {ArrowRightIcon, PlusIcon} from "vue-feather-icons";
import MobileButton from "@/components/createProfile/reuseables/MobileButton";
import school from "@/assets/schools";
import course from "@/assets/courses";

export default {
  name: "EducationHistory",
  components: {MobileButton, BaseButton, ArrowRightIcon, PlusIcon},
  data() {
    return {
      loading: false,
      isEdit: false,
      schools: school.school,
      courses: course.course,
      certifications: ["JSSCE", "GCE", "WAEC", "NECO", "OND", "HND", "BSc", "MSc", "PHD"],
      employmentTypes: ["Full-Time", "Part-Time", "Contract", "Internship", "Flexible"],
      education_history: [
        {
          school_or_training: '',
          degree_or_certificate: '',
          menu: false,
          menu2: false,
          start_date: '',
          end_date: null,
          program_or_course: '',
          still_enrolled: false,
        }
      ]
    }
  },
  async created() {
    if (this.$route.query.edit === 'edit') {
      let res = await this.$store.dispatch('createProfile/getEducationHistory')
      this.isEdit = true
      this.education_history = res
    }
  },
  computed: {
    educations: {
      get() {
        return this.education_history;
      },
      set(educations) {
        this.setUpdatedList(educations);
      }
    },
  },
  methods: {
    deleteExperience(id) {
      this.education_history.splice(id, 1)
    },
    setUpdatedList(educations) {
      this.education_history = educations;
    },
    save(date) {
      this.$refs.menu2.save(date);
    },
    addExperience() {
      let lastGuy = this.educations[this.educations.length - 1];
      if (lastGuy === undefined) {
        let newOption = {
          school_or_training: '',
          degree_or_certificate: '',
          start_date: '',
          end_date: null,
          program_or_course: '',
          still_enrolled: false,
        };
        this.experiences.push(newOption);
      } else {
        let newOption = {
          school_or_training: '',
          degree_or_certificate: '',
          start_date: '',
          end_date: null,
          program_or_course: '',
          still_enrolled: false,
        };
        this.educations.push(newOption);
      }
    },
    toggleAm(index) {
      console.log(index)
      this.educations[index].end_date = null;
    },
    async moveToStepFive() {
      this.loading = true
      try {
        if (this.education_history.some(m => !m.start_date.trim())) {
          this.$displaySnackbar({
            message: 'Kindly select a start date',
            success: false
          })
          return
        } else if (this.education_history.some(m => m.end_date === null && !m.still_enrolled)) {
          this.$displaySnackbar({
            message: 'Kindly select an end date',
            success: false
          })
          return
        }
        // if (this.education_history.some(m => m.still_enrolled)) {
        //   await this.$router.push({
        //     params: {step: "5"},
        //   })
        // } else {
        await this.$store.dispatch('createProfile/createEducationHistory', this.education_history)
        this.$displaySnackbar({
          message: 'Education History Saved',
          success: true
        })
        await this.$router.push({
          params: {step: this.$route.query.edit === 'edit' ? 'preview-resume' : "5"},
        })
        // }
      } catch (e) {
        this.$displaySnackbar({
          message: e.response.data.detail,
          success: false
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.custom-class {
  color: white;
}

.err {
  color: red;
}

.delete {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  cursor: pointer;
}

.delete:hover {
  background-color: #9CABB5;
}

.delete:hover ::v-deep svg {
  color: #FFFFFF;
}

</style>