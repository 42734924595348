export default {
  school: [
    "Ahmadu Bello University, Zaria",
    "Bayero University, Kano",
    "Federal University Gashua, Yobe",
    "Federal University of Petroleum Resources, Effurun",
    "Federal University of Technology, Akure",
    "Federal University of Technology, Minna",
    "Federal University of Technology, Owerri",
    "Federal University, Dutse, Jigawa State",
    "Federal University, Dutsin-Ma, Katsina",
    "Federal University, Kashere, Gombe State",
    "Federal University, Lafia, Nasarawa State",
    "Federal University, Lokoja, Kogi State",
    "Alex Ekwueme University, Ndufu-Alike, Ebonyi State",
    "Federal University, Otuoke, Bayelsa",
    "Federal University, Oye-Ekiti, Ekiti State",
    "Federal University, Wukari, Taraba State",
    "Federal University, Birnin Kebbi",
    "Federal University, Gusau Zamfara",
    "Michael Okpara University of Agricultural Umudike",
    "Modibbo Adama University of Technology, Yola",
    "National Open University of Nigeria, Lagos",
    "Nigeria Police Academy Wudil",
    "Nigerian Defence Academy Kaduna",
    "Nnamdi Azikiwe University, Awka",
    "Obafemi Awolowo University,Ile-Ife",
    "University of Abuja, Gwagwalada",
    "Federal University of Agriculture, Abeokuta",
    "University of Agriculture, Makurdi",
    "University of Benin",
    "University of Calabar",
    "University of Ibadan",
    "University of Ilorin",
    "University of Jos",
    "University of Lagos",
    "University of Maiduguri",
    "University of Nigeria, Nsukka",
    "University of Port-Harcourt",
    "University of Uyo",
    "Usumanu Danfodiyo University",
    "Nigerian Maritime University Okerenkoko, Delta State",
    "Air Force Institute of Technology, Kaduna",
    "Nigerian Army University Biu",
    "Abia State University, Uturu",
    "Adamawa State University Mubi",
    "Adekunle Ajasin University, Akungba",
    "Akwa Ibom State University, Ikot Akpaden",
    "Ambrose Alli University, Ekpoma",
    "Chukwuemeka Odumegwu Ojukwu University, Uli",
    "Bauchi State University, Gadau",
    "Benue State University, Makurdi",
    "Yobe State University, Damaturu",
    "Cross River State University of Technology, Calabar",
    "Delta State University Abraka",
    "Ebonyi State University, Abakaliki",
    "Ekiti State University",
    "Enugu State University of Science and Technology, Enugu",
    "Gombe State Univeristy, Gombe",
    "Ibrahim Badamasi Babangida University, Lapai",
    "Ignatius Ajuru University of Education,Rumuolumeni",
    "Imo State University, Owerri",
    "Sule Lamido University, Kafin Hausa, Jigawa",
    "Kaduna State University, Kaduna",
    "Kano University of Science & Technology, Wudil",
    "Kebbi State University of Science and Technology, Aliero",
    "Kogi State University Anyigba",
    "Kwara State University, Ilorin",
    "Ladoke Akintola University of Technology, Ogbomoso",
    "Ondo State University of Science and Technology Okitipupa",
    "River State University",
    "Olabisi Onabanjo University, Ago Iwoye",
    "Lagos State University, Ojo",
    "Niger Delta University Yenagoa",
    "Nasarawa State University Keffi",
    "Plateau State University Bokkos",
    "Tai Solarin University of Education Ijebu Ode",
    "Umar Musa Yar’ Adua University Katsina",
    "Osun State University Osogbo",
    "Taraba State University, Jalingo",
    "Sokoto State University",
    "Yusuf Maitama Sule University Kano",
    "Oyo State Technical University Ibadan",
    "Ondo State University of Medical Sciences",
    "Edo University Iyamo",
    "Eastern Palm University Ogboko, Imo State",
    "University of Africa Toru Orua, Bayelsa State",
    "Bornu State University, Maiduguri",
    "Moshood Abiola University of Science and Technology Abeokuta",
    "Gombe State University of Science and Technology",
    "Zamfara State University",
    "Bayelsa Medical University",
    "Achievers University, Owo",
    "Adeleke University, Ede",
    "Afe Babalola University, Ado-Ekiti – Ekiti State",
    "African University of Science & Technology, Abuja",
    "Ajayi Crowther University, Ibadan",
    "Al-Hikmah University, Ilorin",
    "Al-Qalam University, Katsina",
    "American University of Nigeria, Yola",
    "Augustine University",
    "Babcock University,Ilishan-Remo",
    "Baze University",
    "Bells University of Technology, Otta",
    "Benson Idahosa University, Benin City",
    "Bingham University, New Karu",
    "Bowen University, Iwo",
    "Caleb University, Lagos",
    "Caritas University, Enugu",
    "Chrisland University",
    "Covenant University Ota",
    "Crawford University Igbesa",
    "Crescent University",
    "Edwin Clark University, Kaigbodo",
    "Elizade University, Ilara-Mokin",
    "Evangel University, Akaeze",
    "Fountain Unveristy, Oshogbo",
    "Godfrey Okoye University, Ugwuomu-Nike – Enugu State",
    "Gregory University, Uturu",
    "Hallmark University, Ijebi Itele, Ogun",
    "Hezekiah University, Umudi",
    "Igbinedion University Okada",
    "Joseph Ayo Babalola University, Ikeji-Arakeji",
    "Kings University, Ode Omu",
    "Kwararafa University, Wukari",
    "Landmark University, Omu-Aran.",
    "Lead City University, Ibadan",
    "Madonna University, Okija",
    "Mcpherson University, Seriki Sotayo, Ajebo",
    "Micheal & Cecilia Ibru University",
    "Mountain Top University",
    "Nile University of Nigeria, Abuja",
    "Novena University, Ogume",
    "Obong University, Obong Ntak",
    "Oduduwa University, Ipetumodu – Osun State",
    "Pan-Atlantic University, Lagos",
    "Paul University, Awka – Anambra State",
    "Redeemer’s University, Ede",
    "Renaissance University, Enugu",
    "Rhema University, Obeama-Asa – Rivers State",
    "Ritman University, Ikot Ekpene, Akwa Ibom",
    "Salem University, Lokoja",
    "Samuel Adegboyega University, Ogwa.",
    "Southwestern University, Oku Owa",
    "Summit University",
    "Tansian University, Umunya",
    "University of Mkar, Mkar",
    "Veritas University, Abuja",
    "Wellspring University, Evbuobanosa – Edo State",
    "Wesley University. of Science & Technology, Ondo",
    "Western Delta University, Oghara Delta State",
    "Christopher University Mowe",
    "Kola Daisi University Ibadan, Oyo State",
    "Anchor University Ayobo Lagos State",
    "Dominican University Ibadan Oyo State",
    "Legacy University, Okija Anambra State",
    "Arthur Javis University Akpoyubo Cross river State",
    "Crown Hill University Eiyenkorin, Kwara State",
    "Coal City University Enugu State",
    "Clifford University Owerrinta Abia State",
    "Admiralty University, Ibusa Delta State",
    "Spiritan University, Nneochi Abia State",
    "Precious Cornerstone University, Oyo",
    "PAMO University of Medical Sciences, Portharcourt",
    "Atiba University Oyo",
    "Eko University of Medical and Health Sciences Ijanikin, Lagos",
    "Skyline University, Kano",
    "Greenfield University, Kaduna",
    "Dominion University Ibadan, Oyo State",
    "Trinity University Ogun State",
    "Westland University Iwo, Osun State",
    "Dorben Polytechnic",
    "Adamawa State Polytechnic",
    "Federal Polytechnic, Mubi",
    "Akwa Ibom State Polytechnic",
    "Akwa-Ibom College of Agriculture",
    "Foundation College of Technology",
    "Maritime Academy of Nigeria",
    "Ekwenugo Okeke Polytechnic",
    "Federal Polytechnic, Oko",
    "Abubakar Tafari Ali Polytechnic",
    "Federal Polytechnic, Bauchi",
    "Bayelsa State College of Arts and Science",
    "Benue State Polytechnic",
    "Akperan Orshi College of Agriculture",
    "Borno College of Agriculture",
    "Ramat Polytechnic",
    "Ibrahim Babangida College of Agriculture",
    "The Polytechnic, Calabar",
    "Delta State College of Agriculture",
    "Delta State Polytechnic",
    "Petroleum Training Institute",
    "Akanu Ibiam Federal Polytechnic",
    "Federal College of Agriculture, Ishiagu",
    "Auchi Polytechnic",
    "Kings Polytechnic",
    "Shaka Polytechnic",
    "Federal Polytechnic, Ado-Ekiti",
    "Federal School of Dental Technology & Therapy",
    "Institute of Management Technology, Enugu",
    "Our Saviour Institute of Science and Technology",
    "Federal College of Land Resources Technology, Owerri",
    "Federal Polytechnic, Nekede",
    "Templegate Polytechnic Aba",
    "Imo State Polytechnic",
    "Imo State Technological Skills Acquisition Center",
    "Hussaini Adamu Federal Polytechnic",
    "Hussani Adamu Polytechnic",
    "College of Agriculture and Animal Science",
    "Federal College of Chemical and Leather and Technology",
    "Federal College of Forestry Mechanisation",
    "Kaduna Polytechnic",
    "Nigerian College of Aviation Technology",
    "Nuhu Bamalli Polytechnic",
    "Samaru College of Agriculture",
    "Audu Bako School of Agriculture",
    "Kano State Polytechnic",
    "Mohammed Abdullahi Wase Polytechnic",
    "Hassan Usman Katsina Polytechnic",
    "College of Agriculture, Zuru",
    "Federal Polytechnic, Birnin-Kebbi",
    "Kebbi State Polytechnic",
    "College of Agriculture, Kabba",
    "Federal Polytechnic, Idah",
    "Kogi State Polytechnic",
    "Federal Polytechnic, Offa",
    "Kwara State Polytechnic",
    "Federal College of Fisheries and Marine Technology",
    "Grace Polytechnic",
    "Lagos City Polytechnic",
    "Lagos State Polytechnic",
    "School of Agriculture, Ikorodu",
    "Wavecrest College of Hospitality",
    "Wolex Polytechnic",
    "Yaba College of Technology",
    "College of Agriculture, Lafia",
    "Maurid Institute of Management & Technology, Nasarawa",
    "Federal Polytechnic, Nassarawa",
    "Nasarawa State Polytechnic",
    "Federal College of Fresh Water Fisheries Technology",
    "Federal College of Wildlife Management",
    "Federal Polytechnic, Bida",
    "Niger State College of Agriculture",
    "Niger State Polytechnic",
    "Allover Central Polytechnic",
    "Federal Polytechnic, Ilaro",
    "Gateway Polytechnic Saapade",
    "Marvic Polytechnic",
    "Moshood Abiola Polytechnic",
    "Rufus Giwa Polytechnic",
    "Federal Polytechnic, Ede",
    "Osun State College of Technology",
    "Osun State Polytechnic",
    "Polytechnic Ile-Ife",
    "Southern Nigeria Institute of Innovative Technology(SNIITPOLY)",
    "Federal College of Animal Health & Production Technology",
    "Federal College of Animal Health and Production Technology, Ibadan",
    "Federal College of Forestry, Ibadan",
    "The Polytechnic, Ibadan",
    "The KINGS Poly, Saki",
    "Tower Polytechnic, Ibadan",
    "Federal College of Animal Health and Production Technology, Vom",
    "Federal College of Education, Pankshin",
    "Federal College of Forestry. Jos",
    "Federal College of Land Resources Technology, Kuru",
    "Plateau State College of Agriculture",
    "Plateau State Polytechnic",
    "Rivers State College of Arts and Science",
    "Rivers State Polytechnic",
    "College of Agriculture, Jalingo",
    "Federal Polytechnic, Damaturu",
    "Mai Idris Alooma Polytechnic",
    "Abdul Gusau Polytechnic",
    "Federal Polytechnic, Namoda",
    "Abdul Gusau Polytechnic",
    "Boys Technical College, Aba, Abia State.",
    "Ohafia Technical College, Ania, Abia State.",
    "Federal Science Technical College, Ohanso, Abia State.",
    "Girls Technical College, Aba, Abia State.",
    "Federal Science Technical College, Michika, Adamawa State.",
    "Government Technical College, Mubi, Adamawa State.",
    "Government Technical College, Numan, Adamawa State.",
    "Government Technical College, Yola, Adamawa State.",
    "Government Technical College, Abak, Akwa Ibom State.",
    "Government Technical College, Ewet, Akwa Ibom State.",
    "Union Technical College, Ikot-Akat, Akwa Ibom State.",
    "Union Technical College, Ikpa-Eket, Akwa Ibom State.",
    "Government Technical College, Oron, Akwa Ibom State.",
    "Federal Science Technical College, Uyo, Akwa Ibom State.",
    "Federal Science Technical College, Awka, Anambra State.",
    "Government Technical College, Onitsha, Anambra State.",
    "Government Technical College, Gadau, Bauchi State.",
    "Government Technical College, Gumau, Bauchi State.",
    "Government Science Technical College, Ekowe, Bayelsa State.",
    "Federal Science Technical College, Tumbgo, Bayelsa State.",
    "Mbakuha Technical College, Lessel, Benue State.",
    "Elabor Technical College, Adoka, Benue State.",
    "Government Technical College, Makurdi, Benue State.",
    "St. Joseph Technical College, Makurdi, Benue State.",
    "Federal Science Technical College, Otukpo, Benue State.",
    "St. Jude's Technical College, Tse-Mker, Benue State.",
    "Usar Technical College, Adikpo, Benue State.",
    "Government Technical College, Bama, Borno State.",
    "Government Technical College, Damboa, Borno State.",
    "Federal Science Technical College, Lassa, Borno State.",
    "Community Technical College, Calabar, Cross River State.",
    "Government Technical College, Obalinku, Cross River State.",
    "Government Technical College, Ogoja, Cross River State.",
    "St. Patrick Technical College, Ugep, Cross River State.",
    "Agbor Technical College, Agbor, Delta State.",
    "Isele-Uku Technical College, Issele-Uku, Delta State.",
    "Utagba-Ogbe Technical College, Kwale, Delta State.",
    "Ofagbe Technical College, Ofagbe, Delta State.",
    "Ogor Technical College, Otogor Ughelli, Delta State.",
    "Sapele Technical College, Sapele, Delta State.",
    "Technical College, Abakaliki, Ebonyi State.",
    "Ehugbo Technical College, Afikpo, Ebonyi State.",
    "Folk Technical College, Ikwo, Ebonyi State.",
    "Afuze Technical College, Afuze, Edo State.",
    "Igara Technical College, Igarra, Edo State.",
    "Benin Technical College, Ugbowo, Edo State.",
    "Federal Science Technical College, Uromi, Edo State.",
    "Government Science Technical College, Urhonigbe, Edo State.",
    "Government Science Technical College, Irrua, Edo State.",
    "Government Science Technical College, Agbede, Edo State.",
    "Government Technical College, Ado-Ekiti, Ekiti State.",
    "Government Technical College, Ijero-Ekiti, Ekiti State.",
    "Government Technical College, Ikole-Ekiti, Ekiti State.",
    "Government Technical College, Otun-Ekiti, Ekiti State.",
    "Federal Science Technical College, Usi, Ekiti State.",
    "Government Technical College, Akpugoeze, Enugu State.",
    "Government Technical College, Enugu, Enugu State.",
    "Government Technical College, Nsukka, Enugu State.",
    "Government Technical College, Garki, Fct State.",
    "Federal College Of Technology, Orozo, Fct State.",
    "Government Technical College, Gombe, Gombe State.",
    "Government Technical College, Kumo, Gombe State.",
    "Government Technical College, Ahiara, Imo State.",
    "Government Technical College, Okporo, Imo State.",
    "Government Technical College, Osu, Imo State.",
    "Government Technical College, Owerri, Imo State.",
    "Government Technical College, Hadejia, Jigawa State.",
    "Federal Science Technical College, Kafanchan, Kaduna State.",
    "Government Technical College, Kajuru, Kaduna State.",
    "Government Technical College, Malali, Kaduna State.",
    "Government Technical College, Soba, Kaduna State.",
    "Government Technical College, Fadan Chawai, Kaduna State.",
    "Government Technical College, Bagauda, Kano State.",
    "Government Technical College, Kano, Kano State.",
    "Government Girls' Technical College, Kano, Kano State.",
    "Government Technical College, Ungogo, Kano State.",
    "Government Technical College, Wudil, Kano State.",
    "Govt. Girls' Tech. Commercial College, Charanchi, Katsina State.",
    "Government Technical College, Funtua, Katsina State.",
    "Government Technical College, Ingawa, Katsina State.",
    "Government Technical College, Mashi, Katsina State.",
    "Government Technical College, Bunza, Kebbi State.",
    "Government Technical College, Zuru, Kebbi State.",
    "Federal Science Technical College, Zuru, Kebbi State.",
    "Government Technical College, Ankpa, Kogi State.",
    "Government Technical College, Idah, Kogi State.",
    "Government Technical College, Mopa, Kogi State.",
    "Government Technical College, Oboroke, Kogi State.",
    "Government Technical College, Amodu Asamegbolu, Kwara State.",
    "Government Technical College, Erin-Ile, Kwara State.",
    "Government Technical College, Esie Iludun, Kwara State.",
    "Government Technical College, Ilorin, Kwara State.",
    "Government Technical College, Patigi, Kwara State.",
    "Government Technical College, Ado-Soba, Lagos State.",
    "Government Technical College, Agidingbi - Ikeja, Lagos State.",
    "Government Technical College, Ikorodu, Lagos State.",
    "Government Technical College, Ikotun, Lagos State.",
    "Government Technical College, Odomola - Epe, Lagos State.",
    "Federal Science Technical College, Yaba, Lagos State.",
    "Government Technical College, Mada Station, Nasarawa State.",
    "Government Technical College, Agwanda, Nasarawa State.",
    "Government Technical College, Asakio, Nasarawa State.",
    "Government Technical College, Eyagi - Bida, Niger State.",
    "Government Technical College, Kontagora, Niger State.",
    "Government Technical College, Minna, Niger State.",
    "Government Technical College, New Bussa, Niger State.",
    "Mamman Kontagora Technical College, Pandogari, Niger State.",
    "Federal Science Technical College, Shiroro, Niger State.",
    "Suleiman Barau Technical College, Suleja, Niger State.",
    "Government Technical College, Aiyetoro, Ogun State.",
    "Government Technical College, Ajegunle, Ogun State.",
    "Government Technical College, Idi-Aba - Abeokuta, Ogun State.",
    "Government Technical College, Igbesa, Ogun State.",
    "Federal Science Technical College, Ijebu Mushin, Ogun State.",
    "Government Technical College, Ijebu-Igbo, Ogun State.",
    "Government Technical College, Ijebu-Ode, Ogun State.",
    "Government Technical College, Ilara-Remo, Ogun State.",
    "Government Technical College, Idanre, Ondo State.",
    "Federal Science Technical College, Ikare Akoko, Ondo State.",
    "Government Technical College, Iwaro-Oka Akoko, Ondo State.",
    "Government Technical College, Okeigbo, Ondo State.",
    "Government Technical College, Okitipupa, Ondo State.",
    "Government Technical College, Owo, Ondo State.",
    "Government Technical College, Ara, Osun State.",
    "Government Technical College, Gbongan, Osun State.",
    "Government Technical College, Ijebu-Jesa, Osun State.",
    "Government Technical College, Ile-Ife, Osun State.",
    "Federal Science Technical College, Ilesa, Osun State.",
    "Government Technical College, Inisa, Osun State.",
    "Government Technical College, Iwo, Osun State.",
    "Government Technical College, Oshogbo, Osun State.",
    "Government Technical College, Osu, Osun State.",
    "Government Technical College, Otun-Ayegbju, Osun State.",
    "Government Technical College, Ibadan, Oyo State.",
    "Government Technical College, Igboora, Oyo State.",
    "Government Technical College, Ogbomosho, Oyo State.",
    "Government Technical College, Oyo, Oyo State.",
    "Government Technical College, Saki, Oyo State.",
    "Government Technical College, Bukuru, Plateau State.",
    "Federal Science Technical College, Ahoada, Rivers State.",
    "Government Technical College, Ahoada, Rivers State.",
    "Government Technical College, Eleogu, Rivers State.",
    "Government Technical College, Port Harcourt, Rivers State.",
    "Government Technical College, Tombia, Rivers State.",
    "Government Technical College, Binji, Sokoto State.",
    "Government Technical College, Farfaru, Sokoto State.",
    "Government Technical College, Rinjin Sambo, Sokoto State.",
    "Government Technical College, Bali, Taraba State.",
    "Government Technical College, Gembu, Taraba State.",
    "Government Technical College, Jalingo, Taraba State.",
    "Federal Science Technical College, Jalingo, Taraba State.",
    "Government Technical College, Karim-Lamido, Taraba State.",
    "Government Technical College, Takum, Taraba State.",
    "Government Technical College, Wukari, Taraba State.",
    "Government Technical College, Geidam, Yobe State."
    // "University of Lagos",
    // "University of Ibadan",
    // "University of Abuja",
    // "University of Agriculture, Abeokuta",
    // "University of Ado-Ekiti",
    // "University of Benin",
    // "University of Calabar",
    // "University of Education, Ikere-Ekiti",
    // "University of Ilorin",
    // "University of Jos",
    // "University of Maiduguri",
    // "University of Mkar, Mkar",
    // "University of Nigeria",
    // "University of port-harcourt",
    // "University of Technology, Akwa-Ibom",
    // "University Of Uyo",
    // "Abia State University, Uturu",
    // "ABTI-American University of Nigeria",
    // "Abubakar Tafawa Balewa University",
    // "Adamawa State University, Mubi",
    // "Adekunle Ajasin University",
    // "Akanu Ibiam Federal Polytechnic Unwana, Ebonyi State",
    // "Auchi Polytechnic, Auchi, Edo State",
    // "Abdu Gusau Polytechnic, Talata Mafara, Zamfara State",
    // "Airforce Institute of Technology (AFIT),NAF Base Kaduna",
    // "Abia State Polytechnic, Aba, Abia State",
    // "Abraham Adesanya Polytechnic, Ijebu-Igbo, Ogun State",
    // "Abubakar Tatari Ali Polytechnic, Bauchi, Bauchi State",
    // "Adamawa State Polytechnic, Yola,Adamawa State",
    // "Akwa Ibom State College of Art & Science, Nung Ukim, Akwa Ibom State",
    // "Akwa Ibom State Polytechnic,Ikot Osurua, Ikot -Ekpene,Akwa Ibom State",
    // "Bayelsa State College of Arts and Science, Elebele, Yenogoa",
    // "Benue State Polytechnic, Ugbokolo, Benue State",
    // "Binyaminu Usman Polytechnic, Hadejia,Jigawa State",
    // "Ahmadu Bello University",
    // "Anambra State University, Anambra",
    // "Ajayi Crowther University, Oyo",
    // "Ambrose Alli University",
    // "BStudents and exam malpracticesock University",
    // "Bayero University, Kano",
    // "Bells University",
    // "Benson Idahosa University",
    // "Bowen University, Iwo",
    // "Cetep City University, Lagos",
    // "Covenant University",
    // "Crawford University,Oye Ekiti",
    // "Cross River University of Technology",
    // "Delta State University, Abraka",
    // "Ebonyi State University",
    // "Enugu State University of Science & Technology",
    // "Federal University of Technology, Akure",
    // "Federal University of Technology, Minna",
    // "Federal University of Technology, Owerri",
    // "Federal University Of Technology, Yola",
    // "Gombe state University",
    // "Tudun, Wada",
    // "Igbinedion University, Okada",
    // "Imo State University",
    // "Joseph Ayo Babalola University",
    // "Katsina State University",
    // "Kogi State University, Anyigba",
    // "Hussaini Adamu Federal Polytechnic, Kazaure Jigawa State",
    // "Kaduna Polytechnic, Kaduna",
    // "National Institute of Construction Technology Uromi",
    // "Waziri Umaru Federal Polytechnic, Birnin Kebbi",
    // "Yaba College of Technology, Yaba, Lagos State",
    // "Petroleum Training Institute Effurun. Delta State",
    // "D.S. Adegbenro ICT Polytechnic, Itori-Ewekoro, Ifo, Ogun State",
    // "Delta State Polytechnic, Ogwashi-Uku,Delta State",
    // "Delta State Polytechnic, Otefe-Oghara, Delta State",
    // "Delta State Polytechnic, Ozoro,Delta State",
    // "Delta State School of Marine Technology, Burutu Warri, Delta State",
    // "Edo State Institute of Technology and Management, Benin City, Edo State",
    // "Enugu State Polytechnic, Iwollo",
    // "Federal Polytechnic Ado Ekiti, Ekiti State",
    // "Federal Polytechnic Bali, Taraba State",
    // "Federal Polytechnic Bauchi, Bauchi State",
    // "Federal Polytechnic Bida, Niger State",
    // "Federal Polytechnic Damaturu, Yobe State",
    // "Federal Polytechnic, Daura, Katsina State",
    // "Federal Polytechnic Ede, Osun State",
    // "Federal Polytechnic Ekowe, Bayelsa State",
    // "Federal Polytechnic Idah, Kogi State",
    // "Federal Polytechnic Ilaro, Ogun State",
    // "Federal Polytechnic Ile-Oluji, Ondo State",
    // "Federal Polytechnic Kaura Namoda, Zamfara State",
    // "Federal Polytechnic Kaltungo, Gombe State",
    // "Federal Polytechnic Mubi, Adamawa State",
    // "Federal Polytechnic Nasarawa, Nasarawa State",
    // "Federal Polytechnic Nekede, Owerri, Imo State",
    // "Federal Polytechnic Offa, Kwara State",
    // "Federal Polytechnic Oko, Anambra State",
    // "Federal Polytechnic of Oil and Gas Bonny, Rivers State",
    // "Federal Polytechnic Ukana, Akwa Ibom State",
    // "Gateway Polytechnic, Saapade, Isara Ode-Remo, Ogun State",
    // "Hassan Usman Katsina Polytechnic (HUK),Katsina, Nigeria",
    // "Ibarapa Polytechnic, EruwaOyo State",
    // "Imo State Polytechnic, Umuagwo, Umuagwo, Ohaji Owerri,Imo State",
    // "Institute of Management and Technology, Enugu,Enugu State",
    // "Institute of Technology and Management (ITM),Cross River State",
    // "Jigawa State Polytechnic, Dutse, Jigawa State",
    // "Kano State Polytechnic, Kano, Kano State",
    // "Ken Sarowiwa Polytechnic, Bori, Rivers State",
    // "Kogi State Polytechnic, Lokoja, Kogi State",
    // "Kwara State Polytechnic, Ilorin, Kwara State",
    // "Lagos State Polytechnic, Ikorodu, Lagos State",
    // "Mai-Idris Alooma Polytechnic, Geidam, Yobe State",
    // "Moshood Abiola Polytechnic, Abeokuta, Ogun State",
    // "Nasarawa State Polytechnic, Lafia, Nasarawa State",
    // "Nigerian Army Institute of Technology and Environmental Science (NAITES) Makurdi",
    // "Nigerian College of Aviation Technology (NCAT), Sokoto Road, Zaria",
    // "Niger State Polytechnic, Zungeru, Niger State",
    // "Nuhu Bamalli Polytechnic, Zaria, Kaduna State",
    // "Ogun State Institute of Technology, Igbesa",
    // "Ogun State Polytechnic, Ipokia",
    // "Oke-Ogun Polytechnic, Shaki,Oyo State",
    // "Osun State College of Technology, Esa – Oke, Osun State",
    // "Osun State Polytechnic, Iree, Osun State",
    // "Oyo State College of Agriculture and Technology, Igbo Ora",
    // "Plateau State Polytechnic, Jos, Plateau State",
    // "Port-Harcourt Polytechnic, Port-Harcourt,Rivers State",
    // "Ramat Polytechnic, Maiduguri, Maiduguri,Borno State",
    // "Rufus Giwa Polytechnic, Owo, Owo Ondo State",
    // "Taraba State Polytechnic, Suntai",
    // "The Polytechnic Ibadan, Oyo State",
    // "Umaru Ali Shinkafi Polytechnic, Sokoto State",
    // "Zamfara State College of Arts and Science, Sokoto Road, Gusau",
    // "Ladoke Akintola University of Tech",
    // "Lagos State University",
    // "Lagos State Polytechnic",
    // "Lead City University",
    // "Michael Okpara University",
    // "Nasarrawa State University",
    // "National Open University",
    // "Niger Delta University",
    // "Nigerian Defence Academy",
    // "Nnamdi Azikiwe University of Agriculture, Umudike",
    // "Obafemi Awolowo University",
    // "Obafemi Awolowo University, ile-ife",
    // "Olabisi Onabanjo University, Ago-Iwoye",
    // "Osun State University",
    // "Pan African University, Lekki",
    // "Plateau State University",
    // "Redeemer’s University",
    // "Renaissance University, Enugu",
    // "Rivers State University of Science and Technology",
    // "Salem University, Lokoja",
    // "St. paul’s University College, Awka",
    // "Theological College of Northern Nigeria,Bukuru",
    // "Tai Solarin University of Education, Ijebu-Ode",
    // "Usmanu Danfodiyo University, Sokoto",
    // "Wesley University, Ondo",
    // "Yaba College of Technology",
  ].sort()
};
