<template>
  <div>
    <p class="step stepFont tw-mt-5">STEP {{count}} OF 8</p>
  </div>
</template>

<script>
export default {
  name: "StepCount",
  props:{
    count :[Number]
  }
}
</script>

<style scoped>
.stepFont{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Enum-Darkblue/2 */

  color: #142E70;
}

</style>