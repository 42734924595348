<template>
  <resume-layout>
    <template v-slot:main-content>
        <div class="stepContent">
          <ValidationObserver v-slot="{handleSubmit}">
          <v-form id="createProfile" lazy-validation @submit.prevent="handleSubmit(moveToNext)">

          <!--        <step-count class="tw-pt-4" id="stepCount" :count="+$route.params.step"/>-->
          <!--        <step-title class="tw-pt-2" id="stepTitle" title="Skills"/>-->
          <!--        <step-description id="stepDesc" description="You can list multiple skills.Press 'tab' or 'enter' to separate skills"/>-->
          <div class="tw-mb-10">
            <h3 class="step-title tw-mt-4 tw-mb-2">Introducing Specialized Profile</h3>
          </div>
          <div class="lg:tw-mt-8 ">
            <v-card flat
                    style="background-color:#FFF7DC; border-radius: 15px; height: 204px;"
            >
            </v-card>
          </div>
          <div class="par tw-mt-10">
            <p>Add work experience, skills, certifications and accomplishments to boost your profile and stand out from other candidates</p>

          </div>
          <div class="tw-flex align-center tw-justify-end tw-mb-4">
            <div class="tw-pr-8 tw-pt-10" style="cursor: pointer">
              <div id="later" class="fontLater" @click="$router.push({name:'ApplicantDashboard'})">
                I’ll do this later
              </div>
            </div>

            <div class="tw-pt-10">
              <base-button id="continue" type="submit"
                           :button-text="$route.query.edit === 'edit'? 'Save': 'Create'" width="128">
                <template #right-icon>
                  <arrow-right-icon style="color: white" class=" tw-ml-2 custom-class"/>
                </template>
              </base-button>
            </div>
          </div>
          </v-form>
          </ValidationObserver>

        </div>
    </template>
  </resume-layout>
</template>

<script>
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import {ArrowRightIcon} from "vue-feather-icons";
import BaseButton from "@/components/reusables/BaseButton";


export default {
  name: "SpecializedPage",
  components: {ResumeLayout, BaseButton, ArrowRightIcon},
  methods: {
    skip() {
      this.$router.push({
        name: "ApplicantDashboard",
      })
    },
    moveToNext() {
      this.$router.push({name: "CreateSpecializeProfile", params: {step: "1"}})
      sessionStorage.removeItem('userInfo')
      sessionStorage.removeItem('userImage')
    }
  },

}
</script>

<style scoped>
.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.fontLater{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #475661
}

.par {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;

}
</style>