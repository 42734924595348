<template>
  <resume-layout>
    <template #main-content>
      <div class="stepContent">
        <!--          <step-count class="pt-n10" :count="1" id="stepOneCount"></step-count>-->
        <!--          <step-title class="tw-pt-2" title="Personal Information" id="stepOneTitle"/>-->
        <loader v-if="pageLoading" :loading="pageLoading"/>
        <validation-observer ref="observer" v-slot="{handleSubmit}">
          <form v-if="!pageLoading" @submit.prevent="handleSubmit(continueCreateProfile)">
            <div class="tw-mb-10">
              <h3 class="step-title tw-mt-4 tw-mb-2">Welcome to Enum, {{ user ? user : '' }}!</h3>
              <span class="profile tw-pl-1 ">Help us personalize your experience</span>
            </div>
            <!--                          <div class="my-5">-->
            <!--                            <ProfilePictureDragAndUpload :imageUploading="imageUploading" @change="upload"/>-->
            <!--                          </div>-->
            <div class="tw-mb-5 tw-mt-2">
              <span class="date">Tell us a bit about yourself</span>
              <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="About you">
                <v-textarea
                    v-model="userinfo.about_me"
                    solo
                    rows="2"
                    hide-details
                    class="mt-1 select-input lg:tw-w-90 tw-w-full"
                    :error-messages="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="mb-1">
              <span class="date">Date of Birth</span>
            </div>
            <div>
              <div class="lg:tw-w-1/2 tw-mb-5">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="formattedDate"
                        placeholder="YYYY-MM-DD"
                        solo
                        hide-details

                        readonly
                        v-model="userinfo.date_of_birth"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-1 select-input lg:tw-w-90 tw-w-full"
                    >
                      <template #append>
                        <vue-feather type="calendar" @click="menu = !menu" style="color: #475661; cursor: pointer"/>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                      v-model="userinfo.date_of_birth"
                      :active-picker.sync="activePicker"
                      :max="maxDate"
                      scrollable
                      no-title
                      min="1950-01-01"
                      @change="save"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!--              <div class="tw-flex tw-pt-5 tw-flex-col">-->
            <!--                <label class="">Gender</label>-->
            <!--                <v-radio-group-->
            <!--                    v-model="gender"-->
            <!--                    row-->
            <!--                    class="lab mt-n1">-->
            <!--                  <v-radio-->
            <!--                      label="Male"-->
            <!--                      value="male"-->
            <!--                  ></v-radio>-->
            <!--                  <v-radio-->
            <!--                      label="Female"-->
            <!--                      value="female"-->
            <!--                  ></v-radio>-->
            <!--                  <v-radio-->
            <!--                      label="Others"-->
            <!--                      value="others"-->
            <!--                  ></v-radio>-->
            <!--                </v-radio-group>-->
            <!--              </div>-->
            <div>
              <div class="width">
                <small>Phone</small>
                <validation-provider
                    v-slot="{ errors }"
                    name="Number"
                    rules="required">
                  <VuePhoneNumberInput
                      v-model="phoneNumber"
                      :preferred-countries="['NG', 'GH', 'US']"
                      class="mb-4"
                      default-country-code="NG"
                      errorColor="red"
                      size="lg"
                      @input="typing=true"
                      @update="updatePhone"

                  />
                  <span class="span" v-show="errors">{{ errors[0] }}</span>
                </validation-provider>
              </div>

              <div class="tw-pt-5 lg:tw-pt-0">
                <span class="date">Location</span>
                <div>
                  <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="location">
                    <span class="err">{{ errors[0] }}</span>

                    <v-combobox
                        v-model="userinfo.location"
                        :items="countries"
                        placeholder="Select Country"
                        solo
                        class="select-input lg:tw-w-95 mt-1"
                    ></v-combobox>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="tw-flex tw-justify-end mb-5">
              <base-button id="profileContinue" type="submit" class="tw-pt-5" :loading="submitLoading"
                           :button-text="$route.query.edit === 'edit' ? 'Save':'Continue'" width="128">
                <template #right-icon>
                  <vue-feather class="ml-1" style="color: #FFFFFF" type="arrow-right"/>
                </template>
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </template>
  </resume-layout>
</template>

<script>
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import moment from 'moment'
import {getAllCountries, updateUserAboutMe, uploadToCloudinary} from "@/services/api/APIService";
import BaseButton from "@/components/reusables/BaseButton";
import {mapGetters} from 'vuex'
import Loader from "../../reusables/Loader";
import VuePhoneNumberInput from "vue-phone-number-input";


export default {
  name: "StepOne",
  components: {Loader, ResumeLayout, BaseButton, VuePhoneNumberInput},
  data() {
    return {
      menu: false,
      photo: '',
      userinfo: {
        about_me: '',
        location: '',
        date_of_birth: null,
        phone_number: ''

      },
      phoneNumber: '',
      activePicker: null,
      getState: true,
      countries: ["Lagos, Nigeria"],
      selectedLocation: '',
      imageInfo: {
        url: "",
        name: "",
        size: 0
      },
      gender: null,
      submitLoading: false,
      imageUploading: false,
      pageLoading: false
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    // 'phoneNumber': {
    //   handler: function (number) {
    //     if (number.length > 14 && number.includes(+234)) {
    //       this.phoneNumber = number.substr(0, 14)
    //     } else if (number.length > 15) {
    //       this.phoneNumber = number.substr(0, 13)
    //     }
    //   }
    // }
  },

  methods: {

    async handleImage(file) {
      this.imageUploading = true
      const formData = new FormData();
      formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("folder", "resume_logo");
      formData.append("file", file);
      const {
        data: {
          secure_url: url,
          original_filename: name,
          bytes: size
        }
      } = await uploadToCloudinary(formData);
      this.imageInfo = {url: url, name: name, size: size};
      this.imageUploading = false
    },
    async upload(file) {
      this.loading = true
      this.file = file
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension)) {
        this.file = "";
        return
      }
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    getAllCountries() {
      getAllCountries().then(res => {
        res.data.data.map((country) => {
          country.states.map((state) => {
            this.countries.push(state.name + ", "  + country.name)
          })
        })
      })
          .catch(err => {
            console.log(err.response.data.detail)
          })
    },
    async continueCreateProfile() {
      await updateUserAboutMe({
        image: this.imageInfo.url,
        about_me: this.userinfo.about_me,
        user_type: 'talent'
      }).then(async res => {
        console.log(res.data)
        sessionStorage.setItem('userInfo', JSON.stringify(this.userinfo))
        await this.$router.push({
          params: {step: this.$route.query.edit === 'edit' ? 'preview-resume' : "2"}
        })
      }).catch(err=>{
        this.$displaySnackbar({
          message: err.response.data,
          success: false
        })
      })

    },
    populateData() {
      const data = this.profileData
      console.log(this.profileData)
      if (data) {
        this.imageInfo.url = data.image
        this.imageInfo.size = data.image_size
        this.imageInfo.name = data.image_name
        this.date = data.date_of_birth
        this.gender = data.gender
        this.selectedLocation = data.location
        this.phoneNumber = data.phone_number
      }
      this.pageLoading = false
    },
    updatePhone({ formattedNumber }) {
      this.userinfo.phone_number = formattedNumber;
    },
  },
  computed: {
    formattedDate() {
      return this.date ? moment(this.date).format('Do MMMM, YYYY') : ''
    },
    ...mapGetters('createProfile', ['profileData']),
    maxDate() {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 18)
      return date.toISOString()
    },
    user() {
      let userInfo = JSON.parse(sessionStorage.getItem('userData'))
      return userInfo.name.split(' ')[0]
    }
  },
  async created() {
    this.pageLoading = true
    await this.$store.dispatch('createProfile/getApplicantData')
    this.getAllCountries()
    this.populateData()
  }

}
</script>

<style scoped>
.date {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #001343
}

.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.select-input {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans;
  display: flex;
  align-items: center;
  color: #001343;
  cursor: pointer;
}

</style>
