<template>
  <div>
    <div  class="lg:tw-ml-36 tw-pt-32 md:tw-ml-7 tw-ml-0 mb-n4">
      <BaseButton v-if="$route.params.step !== '1' && $route.params.step !== '2'" @click="$router.back()" button-text="Back" text color="#2B1C1C">
        <template #left-icon>
          <v-icon color="#2B1C1C">mdi-chevron-left</v-icon>
        </template>
      </BaseButton>
    </div>
    <div
        :style="{paddingLeft: $vuetify.breakpoint.lgAndUp? '16rem':'',paddingRight: $vuetify.breakpoint.lgAndUp? '40%':''}"
        class="applyFlexCenter">
      <slot name="main-content"></slot>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";

export default {
  name: "ResumeLayout",
  components: { BaseButton}
}
</script>

<style scoped lang="scss">
.layout-min-width {
  min-width: 63rem;
}
</style>