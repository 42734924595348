// import {uploadToCloudinary} from "@/services/api/APIService";

import {uploadToBucket} from "@/services/utils/aws";

export const uploadMediaFile = async (file, folder = 'media') => {
    folder = 'talent/' + folder
    if (!(file instanceof File)) throw new TypeError('arg "file" must be a File')
    const { fullPath, key } = await uploadToBucket(file, folder)
    return { url: fullPath, name: file.name, size: file.size, format: file.name.split('.').pop(), key }
}
