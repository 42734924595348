var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:tw-w-2/3 assigned"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({handleSubmit}){return [_c('v-form',{attrs:{"id":"createProfile","lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.moveToNext)}}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Link","rules":{
          regex:'[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('span',[_vm._v("Portfolio Link")]),_c('v-text-field',{style:({
                  minWidth: _vm.$vuetify.breakpoint.smAndDown  ? '' : '604px',
                }),attrs:{"id":"portfolioLink","prefix":"https://","full-width":"","placeholder":"example.com/me","solo":"","hide-details":""},model:{value:(_vm.portfolio.portfolio_link),callback:function ($$v) {_vm.$set(_vm.portfolio, "portfolio_link", $$v)},expression:"portfolio.portfolio_link"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"err tw-w-full"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"tw-pt-6"},[_c('ValidationProvider',{attrs:{"name":"Link","rules":{
          regex:'[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('span',[_vm._v("Linkedin Profile")]),_c('v-text-field',{style:({
                  minWidth: _vm.$vuetify.breakpoint.smAndDown  ? '' : '604px',
                }),attrs:{"id":"linkedinProfile","prefix":"https://","full-width":"","placeholder":"example.com/me","solo":"","hide-details":""},model:{value:(_vm.portfolio.linkedin_profile),callback:function ($$v) {_vm.$set(_vm.portfolio, "linkedin_profile", $$v)},expression:"portfolio.linkedin_profile"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"err"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._l((_vm.other_link),function(info,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOther || _vm.portfolio.others.length >= 1),expression:"showOther || portfolio.others.length >= 1"}],key:index},[_c('div',{staticClass:"tw-flex align-center tw-pt-6"},[_c('div',{staticClass:"tw-pt-5 tw-mr-3"},[_c('svg',{attrs:{"width":"36","height":"100","viewBox":"0 0 36 100","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"opacity":"0.1","x1":"36","y1":"0.5","x2":"-5.6199e-08","y2":"0.499998","stroke":"black"}}),_c('line',{attrs:{"opacity":"0.1","x1":"0.5","y1":"1","x2":"0.499996","y2":"99","stroke":"black"}}),_c('line',{attrs:{"opacity":"0.1","y1":"99.5","x2":"36","y2":"99.5","stroke":"black"}})])]),_c('div',[_c('div',{staticClass:"lg:tw-pr-16"},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('span',[_vm._v("Title")]),_c('div',{staticClass:"delete",attrs:{"id":"deleteLink"},on:{"click":function($event){[_vm.deleteLink(index)]}}},[_c('vue-feather',{attrs:{"size":"17","type":"trash-2"}})],1)]),_c('v-text-field',{style:({
                  minWidth: _vm.$vuetify.breakpoint.smAndDown  ? '' : '555px',
                }),attrs:{"id":"link_title","full-width":"","rules":[_vm.rules.required],"solo":""},model:{value:(info.title),callback:function ($$v) {_vm.$set(info, "title", $$v)},expression:"info.title"}})],1),_c('div',{staticClass:"tw-mt-5 lg:tw-pr-16"},[_c('span',[_vm._v("Link")]),_c('v-text-field',{style:({
                  minWidth: _vm.$vuetify.breakpoint.smAndDown  ? '' : '555px',
                }),attrs:{"id":"link_title","prefix":"https://","full-width":"","rules":[_vm.rules.required,_vm.rules.website],"placeholder":"example.com/me","solo":""},model:{value:(info.value),callback:function ($$v) {_vm.$set(info, "value", $$v)},expression:"info.value"}})],1)])])])}),_c('div',{staticClass:"tw-flex align-center"},[_c('PlusIcon',{staticClass:"tw-cursor-pointer",on:{"click":_vm.addLinks}}),_c('v-btn',{staticClass:"font-weight-bold tw-capitalize ml-n2",attrs:{"id":"addAward","text":""},on:{"click":function($event){return _vm.addLinks()}}},[_vm._v("Add Link")])],1),_c('div',{staticClass:"tw-flex tw-pt-5 tw-pb-5 tw-justify-end",style:({
                  minWidth: _vm.$vuetify.breakpoint.smAndDown  ? '' : '604px',
                  paddingLeft: _vm.$vuetify.breakpoint.smAndDown  ? '2rem' : '',
                })},[_c('div',{staticClass:"tw-pr-5"},[_c('base-button',{attrs:{"id":"skip","button-text":"Skip","outlined":""},on:{"click":function($event){return _vm.skip()}}})],1),_c('div',[_c('base-button',{attrs:{"loading":_vm.loading,"id":"moveToNext","button-text":_vm.$route.query.edit === 'edit'?'Save': 'Continue',"width":"128","type":"submit"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('arrow-right-icon',{staticClass:"tw-ml-2 custom-class",staticStyle:{"color":"white"}})]},proxy:true}],null,true)})],1)])],2)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }