<template>
  <div class="lg:tw-w-2/3 assigned">
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form id="createProfile" lazy-validation @submit.prevent="handleSubmit(moveToNext)">
        <div>
          <ValidationProvider
              name="Link"
              :rules="{
            regex:'[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          }"
              v-slot="{ errors }"
          >
            <span>Portfolio Link</span>
            <v-text-field :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                  }"
                          id="portfolioLink"
                          v-model="portfolio.portfolio_link"
                          prefix="https://"
                          full-width
                          placeholder="example.com/me"
                          solo
                          hide-details/>
            <span v-show="errors" class="err tw-w-full">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="tw-pt-6">
          <ValidationProvider
              name="Link"
              :rules="{
            regex:'[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
          }"
              v-slot="{ errors }"
          >
            <span>Linkedin Profile</span>
            <v-text-field :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                  }"
                          id="linkedinProfile"
                          v-model="portfolio.linkedin_profile"
                          prefix="https://"
                          full-width
                          placeholder="example.com/me"
                          solo
                          hide-details/>
            <span v-show="errors" class="err">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div v-show="showOther || portfolio.others.length >= 1" v-for="(info, index) in other_link" :key="index">
          <div class="tw-flex align-center tw-pt-6">
            <div class="tw-pt-5 tw-mr-3">
              <svg width="36" height="100" viewBox="0 0 36 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line opacity="0.1" x1="36" y1="0.5" x2="-5.6199e-08" y2="0.499998" stroke="black"/>
                <line opacity="0.1" x1="0.5" y1="1" x2="0.499996" y2="99" stroke="black"/>
                <line opacity="0.1" y1="99.5" x2="36" y2="99.5" stroke="black"/>
              </svg>
            </div>

            <div>
              <div
                  class="lg:tw-pr-16">
                <div class="tw-flex tw-justify-between">

                  <span>Title</span>
                  <div id="deleteLink" class="delete"
                       @click="[deleteLink(index)]">
                    <vue-feather size="17" type="trash-2"/>
                  </div>
                </div>
                <v-text-field :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '555px',
                  }"
                              id="link_title"
                              v-model="info.title"
                              full-width
                              :rules="[rules.required]"
                              solo
                />
              </div>

              <div class="tw-mt-5 lg:tw-pr-16"
              >
                <span>Link</span>

                <v-text-field :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '555px',
                  }"
                              id="link_title"
                              v-model="info.value"
                              prefix="https://"
                              full-width
                              :rules="[rules.required,rules.website]"
                              placeholder="example.com/me"
                              solo
                />
              </div>
            </div>

          </div>
        </div>
        <div class="tw-flex align-center">
          <PlusIcon @click="addLinks" class="tw-cursor-pointer"/>
          <v-btn id="addAward" text class="font-weight-bold tw-capitalize ml-n2" @click="addLinks()">Add Link</v-btn>
        </div>
        <div :style="{
                    minWidth: $vuetify.breakpoint.smAndDown  ? '' : '604px',
                    paddingLeft: $vuetify.breakpoint.smAndDown  ? '2rem' : '',
                  }" class="tw-flex  tw-pt-5 tw-pb-5 tw-justify-end">
          <div class="tw-pr-5">
            <base-button id="skip" button-text="Skip" outlined @click="skip()"/>

          </div>
          <div>
            <base-button :loading="loading" id="moveToNext"
                         :button-text="$route.query.edit === 'edit'?'Save': 'Continue'"
                         width="128" type="submit">
              <template #right-icon>
                <arrow-right-icon style="color: white" class=" tw-ml-2 custom-class"/>
              </template>
            </base-button>
          </div>
        </div>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import {v4 as uuid} from 'uuid';
import {removeUnwantedProperties} from "@/services/utils/removeUnwantedProperties";
import {ArrowRightIcon, PlusIcon} from "vue-feather-icons";

export default {
  name: "PortfolioLinks",
  components: {BaseButton, ArrowRightIcon, PlusIcon},
  data() {
    return {
      isValid: false,
      rules: {
        required: value => !!value || 'Required.',
        website: value => this.validateLink(value) || 'Invalid website.',
      },
      loading: false,
      showOther: false,
      portfolio: {
        portfolio_link: '',
        linkedin_profile: '',
        others: []
      }

    }
  },

  async created() {
    if (this.$route.query.edit === 'edit') {
      const res = await this.$store.dispatch('createProfile/getPortfolio')
      this.portfolio = res
      this.portfolio.others.map(m => m)
    }
  },
  watch: {},
  computed: {
    other_link: {
      get() {
        return this.portfolio.others;
      },
      set(other_link) {
        this.setUpdatedList(other_link);
      }
    },
  },
  methods: {
    deleteLink(id) {
      this.portfolio.others.splice(id, 1)
    },
    validateLink(data) {
      let valid = false
      const re = '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' + '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)'
      let reg = new RegExp(re)
      if (data.match(reg)) {
        valid = true
      } else {
        valid = false
      }
      return valid
    },
    setUpdatedList(other_link) {
      this.portfolio.others = other_link;
    },
    addLinks() {
      this.showOther = true
      let lastGuy = this.other_link[this.other_link.length - 1];
      if (lastGuy === undefined) {
        let newOption = {
          id: uuid(),
          title: '',
          value: ''
        };
        this.other_link.push(newOption);
      } else {
        let newOption = {
          id: uuid(),
          title: '',
          value: ''
        };
        this.other_link.push(newOption);
      }
    },
    skip() {
      this.$router.push({
        path: "6",
      })
    },
    async moveToNext() {
      this.loading = true
      let res = []
      if (this.portfolio.others.some(({ title, value }) => !title || !value || !this.validateLink(value))) {
        this.loading = false
        return
      }
      this.portfolio.others.map(m => {
        console.log(m)
        m.link = m.value
        res.push(removeUnwantedProperties(m, ['id', 'value']))
      })
      // this.portfolio.others = res.map(v => v.value)
      this.portfolio.others = res
      try {
        let m = {'title': 'LinkedIn', 'link': this.portfolio.linkedin_profile}
        let portfolio = {'title': 'Portfolio', 'link': this.portfolio.portfolio_link}
        let sendData = [m, portfolio, ...this.portfolio.others]
        if (sendData.every(d => d.link === '')) {
          await this.$router.push({
            params: {step: '6'},
          })
          return
        } else {
          sendData.forEach(d => d.link = d.link.includes('https://') || d.link.includes('http://') ? d.link : `https://${d.link}`)
          let data = sendData
          console.log(data)
          await this.$store.dispatch('createProfile/createPortfolio', data)
          this.$displaySnackbar({
            message: 'Portfolio Link Saved',
            success: true
          })
          await this.$router.push({
            params: {step: '6'},
          })
        }
      } catch (e) {
        this.$displaySnackbar({
          message: 'an error occurred',
          success: false
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
span {
  padding-bottom: 2px !important;
}

.err {
  color: red;
}

.delete {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  cursor: pointer;
}
</style>