<template>
  <resume-layout>
    <template #main-content>
      <div class="stepContent">
        <!--        <step-count class="pt-n10" :count="2" id="stepTwoCount"></step-count>-->
        <!--        <step-title class="tw-pt-2" title="Job Preference" id="stepOneTitle"/>-->
        <!--        <step-description textColor="#9CABB5"  description="We will use this data to personalize your experience"/>-->
        <loader v-if="pageLoading" :loading="pageLoading"/>
        <ValidationObserver ref="observer" v-slot="{handleSubmit}">
          <form v-if="!pageLoading" @submit.prevent="handleSubmit(continueResume)">
            <div class="tw-mb-10">
              <h3 class="step-title tw-mt-4 tw-mb-2">Job Preference</h3>
              <span class="profile tw-pl-1 ">This data will only be used to customize your job search experience</span>
            </div>
            <div class="mt-7">
              <div>
                <small style="color: red" v-if="showError.state">{{ showError.message }}</small>
              </div>
                <span class="job">Job preference</span>
              <ValidationProvider
                  v-slot="{ errors }"
                  name="job preference"
                  rules="required"
                  >
                <br><span class="err">{{ errors[0] }}</span>

                <v-combobox v-model="select"
                            :items="items"
                            label="Enter your job preference"
                            multiple
                            class="tw-pt-10"
                            chips
                            solo>
                  <template v-slot:selection="data">
                    <v-chip class="tw-flex tw-mt-10"
                            :key="JSON.stringify(data.item)"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            :disabled="data.disabled"
                            @click:close="data.parent.selectItem(data.item)">
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>

              </ValidationProvider>

            </div>
            <div>
              <span class="search">Job Search Stage</span>
              <div>
                <span class="profile tw-pl-1" style="color: #6b778c "> This will be displayed on your profile. <br/>Visible only to recruiters
                  <!--                  <span style="color: #FF2E2E">(Not looking and closed to offers)</span>-->
                </span>
              </div>
              <div class="tw-flex tw-flex-wrap tw-w-full mb-3 tw-mt-5 tw-cursor-pointer">
                <div v-for="jobCurrentStatus in jobStatus" :key="jobCurrentStatus"
                     @click="handleSelectedEducation(jobCurrentStatus)"
                     :class="[jobCurrentStatus === selectedSearch ? 'selected' : 'select']"
                     class="tw-mr-5 tw-mb-5"
                >
                  <span>{{ jobCurrentStatus }}</span>
                </div>
              </div>

            </div>
            <div class="tw-flex tw-justify-end mb-5">
              <base-button
                  id="profileContinue"
                  class="tw-pt-5"
                  button-text="Continue"
                  width="128"
                  :loading="submitLoading"
              >
                <template #right-icon>
                  <vue-feather style="color: #FFFFFF" type="arrow-right" class="ml-1"></vue-feather>
                </template>
              </base-button>
            </div>

          </form>
        </ValidationObserver>
      </div>
    </template>
  </resume-layout>
</template>

<script>
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import BaseButton from "@/components/reusables/BaseButton";
import {mapGetters} from 'vuex'
import Loader from "../../reusables/Loader";
export default {
  name: "StepTwo",
  components: {Loader, ResumeLayout, BaseButton},
  data() {
    return {
      pageLoading: false,
      select: [],
      items: [
        'Product Manager',
        'Product Designer (Ux)',
        'Frontend Software Engineer',
        'Backend Software Engineer',
        'Data Analyst',
        'Content Marketing Associate',
        'Seo Consultant',
        'System Administrator',
        'Visual Designer',
        'Software Training Facilitator',
        'Graphic Designer',
        'Mobile Developer',
        'Full Stack Developer',
        'IT Help Desk Technician',
        'Social Media Officer',
        'Web Developer',
        'Project Management Analyst',
        'Data Analyst',
        'Web Analytics Developer',
        'Digital Marketing Manager',
        'Network Support Engineer',
        'Regional Developer',
        'Content Manager',
        'Content Strategist',
        'Frontend React Native',
        'UI Designer',
        'Wifi Project Engineer',
        'Integration Engineer',
        'QA Engineer',
        'Solution And Research Engineer',
        'Technology Cloud Sales Representative',
        'Java Microservices Engineer',
        'Interaction Designer',
        'DevOps Engineer',
        'Technical Writer',
        'Customer Engagement Manager',
        'Software Developers',
        'WordPress Developer',
        'Frameworks Specialist',
        'Ruby On Rails Developer',
        'Python Developer',
        'Business Systems Analyst',
        'Blockchain Developer',
        'Maintenance Engineer And Machine Operator',
        'Data Architect',
        'Data Modeler',
        'Software Developer',
        'Data Scientist',
        'Cloud Architect',
        'Technical Lead',
        'Devops Manager',
        'Agile Project Manager',
        'Technical Account Manager',
        'Security Specialist',
        'QA (Quality Assurance) Specialist',
        'Game Developer',
        'Computer Graphics Animator',
        'Machine Learning Engineer',
        'Senior Backend Engineer'
      ],
      jobStatus: ['Actively looking', 'Not looking but open to offers', 'Closed to offers'],
      selectedSearch: '',
      submitLoading: false,
      showError:{
        message: "",
        state: false
      }

    }
  },
  computed: {
    ...mapGetters('createProfile', ['profileData'])
  },
  methods: {
    handleSelectedEducation(item) {
      return this.selectedSearch = item
    },

    async continueResume() {
      this.submitLoading = true

      let data = {}
      data.job_preference = this.select
      data.job_search_stage = this.selectedSearch.toLowerCase().split(' ').join('_')
      let otherInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      let imageInfo = JSON.parse(sessionStorage.getItem('userImage'))
      data.about_me = otherInfo.about_me
      data.location = otherInfo.location
      data.date_of_birth = otherInfo.date_of_birth
      data.image = imageInfo.resume_url
      data.image_size = imageInfo.resume_size
      data.image_name = imageInfo.resume_name
      if (data.job_search_stage === '') {
        this.$displaySnackbar({message: 'Select a job search stage', success: false})
        this.submitLoading = false
        return
      }
      if(this.select.length >=4){
        this.showError.state = true
        this.showError.message ="Field exceeds maximum number of 3"
        this.submitLoading = false
        return false
      }
      else {
        this.showError.message = ""
        this.showError.state = false
      }
      try {
        await this.$store.dispatch('createProfile/applicantProfile', data)
        this.submitLoading = false
        await this.$router.push({
          params: {step: "4"}
        })
      } catch (e) {
        this.$displaySnackbar({
          message: e.response.data.message,
          success: false
        })
        this.submitLoading = false
      } finally {
        this.submitLoading = false
      }
    },

    populateData() {
      let data = this.profileData
      if (data) {
        this.select = data.job_preference
        this.selectedSearch = data.job_search_stage.replace(data.job_search_stage.charAt(0), data.job_search_stage.charAt(0).toUpperCase())
        console.log(this.selectedSearch)
      }
      this.pageLoading = false
    }
  },
  async created() {
    this.pageLoading = true
    await this.$store.dispatch('createProfile/getApplicantData')
    this.populateData()
  }
}
</script>

<style scoped>
.job {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
}

.search {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.select-input {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: DM Sans;
  color: #001343;
  cursor: pointer;
}

.selected {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 2px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #008EEF;
  cursor: pointer;
}

.select {
  padding: 16px 25px 25px;
  width: max-content;
  height: 56px;
  border: 2px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.tit {
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  /* Enum-Grey/2 */

  color: #475661;
}

.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

::v-deep .v-chip.v-size--default {
  box-sizing: border-box;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
