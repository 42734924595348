export default {
  course: [
    "Accounting",
    "Computer Science",
    "Insurance",
    "Computer Engineering",
    "Agriculture",
    "Agricultural Economics",
    "Agricultural Extension",
    "Agronomy",
    "Animal Science",
    "Crop Science",
    "Food Science and Technology",
    "Fisheries",
    "Forest Resources Management (Forestry)",
    "Home Science, Nutrition and Dietetics",
    "Soil Science",
    "Archeology and Tourism",
    "Arabic and Islamic Studies",
    "Christian Religious Studies",
    "English and Literary Studies",
    "Fine and Applied Arts (Creative Arts)",
    "Foreign Languages and Literature",
    "History and International Studies",
    "Linguistics and Nigerian Languages",
    "Mass Communication (Communication and Language Arts)",
    "Music",
    "Theatre and Film Studies",
    "Biochemistry",
    "Botany",
    "Microbiology",
    "Law",
    "Marine Biology",
    "Cell Biology & Genetics",
    "Zoology",
    "Accountancy",
    "Actuarial Science",
    "Business Administration",
    "Business Management",
    "Banking and Finance",
    "Hospitality and Tourism",
    "Marketing",
    "Insurance",
    "Industrial Relations and Personnel Management",
    "Child Dental Health",
    "Oral and Maxillofacial Surgery",
    "Preventive Dentistry",
    "Restorative Dentistry",
    "Adult Education and Extra-Mural Studies",
    "Arts Education",
    "Education & Accountancy",
    "Education & Computer Science",
    "Education & Economics",
    "Education & Mathematics",
    "Education & Physics",
    "Education & Religious Studies",
    "Education & Social Science",
    "Education And Biology",
    "Education And Chemistry",
    "Education And English Language",
    "Education And French",
    "Education And Geography/Physics",
    "Education And Political Science",
    "Educational Foundations",
    "Educational / Psychology Guidance And Counselling",
    "Health and Physical Education",
    "Library and Information Science",
    "Science Education",
    "Social Sciences Education",
    "Vocational Teacher Education (Technical Education)",
    "Religion",
    "Igbo Linguistics",
    "Agricultural and Bio-resources Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Computer Engineering",
    "Electrical Engineering",
    "Electronic Engineering",
    "Marine Engineering",
    "Mechanical Engineering",
    "Metallurgical and Materials Engineering",
    "Petroleum and Gas Engineering",
    "Systems Engineering",
    "Structural Engineering",
    "Production and Industrial Engineering",
    "Architecture",
    "Estate Management",
    "Quantity Surveying",
    "Building",
    "Geo-informatics and Surveying",
    "Urban and Regional Planning",
    "Health Administration and Management",
    "Medical Laboratory Sciences",
    "Medical Radiography and Radiological Sciences",
    "Medical Rehabilitation",
    "Nursing Sciences",
    "Commercial and Property Law",
    "International and Jurisprudence",
    "Private and Public Law",
    "Anatomy",
    "Anesthesia",
    "Chemical Pathology",
    "Community Medicine",
    "Dermatology",
    "Hematology and Immunology",
    "Medical Biochemistry",
    "Medical Microbiology",
    "Medicine",
    "Morbid Anatomy",
    "Obstetrics and Gynecology",
    "Ophthalmology",
    "Otolaryngology",
    "Pediatrics",
    "Pharmacology and Therapeutics",
    "Physiology",
    "Radiation Medicine",
    "Surgery",
    "Psychological Medicine",
    "Child Dental Health",
    "Clinical Pharmacy and Pharmacy Management",
    "Pharmaceutical Chemistry and Industrial Pharmacy",
    "Pharmaceutical Technology and Industrial Pharmacy",
    "Pharmaceutics",
    "Pharmacognosy",
    "Department of Pharmacology and Toxicology",
    "Computer Science",
    "Geology",
    "Mathematics",
    "Physics and Astronomy",
    "Geophysics",
    "Pure and Industrial Chemistry",
    "Statistics",
    "Economics",
    "Geography",
    "Philosophy",
    "Political Science",
    "Psychology",
    "Public Administration and Local Government",
    "Religion",
    "Social Work",
    "Sociology/Anthropology",
    "Veterinary Physiology/Pharmacology",
    "Veterinary Anatomy",
    "Animal Health and Production",
    "Veterinary Parasitology and Entomology",
    "Veterinary Pathology and Microbiology",
    "Veterinary Public Health and Preventive Medicine",
    "Veterinary Surgery",
    "Veterinary Medicine",
    "Veterinary Obstetrics and Reproductive Diseases",
    "Veterinary Teaching Hospital",
  ].sort()
};
