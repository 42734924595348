<template>
  <resume-layout>
    <template v-slot:main-content>
      <div class="stepContent">
<!--        <step-count class="tw-pt-4" :count="+$route.params.step"/>-->
<!--        <step-title class="tw-pt-2" id="education" title="Education"/>-->
<!--        <step-description description="We will use this data to personalize your experience,"/>-->
        <div class="tw-mb-10">
          <h3 class="step-title tw-mt-4 tw-mb-2">Education</h3>
          <span class="profile ">Your educational background will be displayed on your profile.</span>
        </div>
        <div class="lg:tw-mt-8 ">
          <EducationHistory/>
        </div>

      </div>
    </template>
    <template v-slot:footer>

    </template>
  </resume-layout>
</template>

<script>
import ResumeLayout from "@/components/createProfile/reuseables/ResumeLayout";
import EducationHistory from "@/components/createProfile/components/EducationHistory";
export default {
  name: "StepFive",
  components: {EducationHistory, ResumeLayout}

}
</script>

<style scoped>
.profile {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
</style>